import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import moment from 'moment';

@Pipe({
    name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe) {        
    }
    
    transform(value: moment.Moment, format: string): string {
        if(value) {
            return value.format(format);
        }
        return '';
    }

}
