import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AccessTokenInterceptor } from '../shared/accessToken.interceptor';
import { LayoutModule } from '@angular/cdk/layout';
import { ConfirmDialogComponent } from './confirm-dialog.component';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TitleCasePipe } from '@angular/common';
import { CurrencyPipe } from '@angular/common';
import { DecimalPipe } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CurrencyItaPipe } from '../core/currency-ita.pipe';
import { MomentDatePipe } from '../core/moment-date.pipe';
import { MatStepperModule } from '@angular/material/stepper';

// declare components, directives, and pipes in a shared module when those items will be re-used
// and referenced by the components declared in other feature modules.
//
// SharedModule exists to make commonly used components, directives and pipes available for use in
// the templates of components in many other modules
//
// NO services in shared modules.
// A lazy loaded feature module that imports that shared module will make its own copy of the service and likely have undesirable results

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,    // Template driven approach
    ReactiveFormsModule,
    HttpClientModule,
    LayoutModule,
    // material
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatListModule,
    MatTabsModule,
    MatIconModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatMenuModule,
    MatTreeModule,
    MatToolbarModule,
    ScrollingModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatStepperModule,
    MatRippleModule
  ],
  declarations: [
    CurrencyItaPipe,
    MomentDatePipe,
    ConfirmDialogComponent
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LayoutModule,
    CurrencyItaPipe,
    MomentDatePipe,
    // material
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatListModule,
    MatTabsModule,
    MatIconModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatMenuModule,
    MatTreeModule,
    MatToolbarModule,
    ScrollingModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,    
    MatStepperModule,
    MatRippleModule
  ],
  providers: [
    TitleCasePipe,
    CurrencyPipe,
    CurrencyItaPipe,
    MomentDatePipe,
    DecimalPipe,
    ConfirmDialogComponent,
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    { provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptor, multi: true },
  ],
})
export class SharedModule {
    constructor() {
        const ts = new Date();
        console.log('[' + ts.toLocaleTimeString() + '.' + ts.getMilliseconds() + '] Loading module: "SharedModule"');
    }
}

